// extracted by mini-css-extract-plugin
export var agentTag = "UserProjectDetailsOwner__agentTag__HqMt_";
export var column = "UserProjectDetailsOwner__column__pUbBx";
export var container = "UserProjectDetailsOwner__container__IftxR";
export var designationText = "UserProjectDetailsOwner__designationText__rdG6L";
export var fullName = "UserProjectDetailsOwner__fullName__K6t8u";
export var heading = "UserProjectDetailsOwner__heading__vXih8";
export var imageWrapper = "UserProjectDetailsOwner__imageWrapper__uS24Z";
export var nameAndTagWrapper = "UserProjectDetailsOwner__nameAndTagWrapper__NIDZi";
export var ownerDescriptionWrapper = "UserProjectDetailsOwner__ownerDescriptionWrapper__pNqtM";
export var ownerDetailsWrapper = "UserProjectDetailsOwner__ownerDetailsWrapper__dYQbx";
export var row = "UserProjectDetailsOwner__row__HmTLF";